import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useCommonStore from '../../../hooks/useCommonStore';

const CertEmail = observer(({ email, resultPath, readOnly, history, showHeader = true }) => {
  const commonStore = useCommonStore();
  const [sendEmail, setSendEmail] = useState(email);
  const [sendComplete, setSendComplete] = useState(false);
  const [emailDomain, setEmailDomain] = useState();
  resultPath = `${window.location.origin}/${resultPath}`;

  const onAuthNumSend = async () => {
    //이메일이 바뀌면 안되는 경우
    if (readOnly) {
      if (email !== sendEmail) {
        return false;
      }
    }

    const res = await commonStore.actionCertifyEmail({
      email: sendEmail,
      resultUrl: resultPath
    });

    if (res.code === 200) {
      setSendComplete(true);
    }
  };

  const onChangeEmail = (e) => {
    setSendEmail(e.target.value);
  };

  const onBack = () => {
    history.goBack();
  };

  useEffect(() => {
    setSendEmail(email);
    let emailSplit = email.split('@');
    setEmailDomain('htttp://' + emailSplit[1]);
  }, [email, sendComplete]);

  return (
    <>
      {sendComplete ? (
        <div className="account_info_wrap">
          {
            showHeader &&
            <div className="nav">
              <h2 className="tit">이메일 인증</h2>
              <button className="btn_prev" onClick={onBack}>
                이전으로
              </button>
            </div>
          }
          <div className="sleeper_area">
            <div className="email_auth">
              <p>
                <strong>{sendEmail}</strong>(으)로 인증 메일을 발송 하였습니다.
              </p>
              <p>
                메일을 10분 이내에 확인한 후 메일 내용의 링크를 클릭하면 인증을
                완료하실 수 있습니다.
              </p>
            </div>
          </div>
          <div className="tip">
            <p>
              메일을 받지 못하셨나요?{' '}
              <a className="lnk" href="javascript:;" onClick={onAuthNumSend}>
                인증메일 재전송
              </a>
            </p>
          </div>
        </div>
      ) : (
        <div className="account_info_wrap">
          {showHeader &&
            <div className="nav">
              <h2 className="tit">이메일 인증</h2>
              <button className="btn_prev" onClick={onBack}>
                이전으로
              </button>
            </div>
          }
          <div className="find_area">
            <div className="result">
              <p className="tx">
                아래 가입한 아이디(이메일)로 인증 메일을 발송 합니다.
              </p>
              <p className="email">{sendEmail}</p>
            </div>
            <div className="btn_area">
              <button type="button" className="btn" onClick={onBack}>
                취소
              </button>
              <button
                type="submit"
                className="btn btn_submit"
                onClick={onAuthNumSend}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default CertEmail;
