import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Modal from 'react-modal';
import { observer } from 'mobx-react';
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock"
import '../style.css'

import useModalStore from '../../../hooks/useModalStore';

Modal.setAppElement('#root');

const ConfirmModal = observer(forwardRef(({ option }, ref) => {
  const modalRef = useRef()

  useImperativeHandle(ref, () => modalRef.current, [modalRef])

  const modalStore = useModalStore();

  const isOpen = option?.isOpen;

  useEffect(() => {
    if (!isOpen) {
      enableBodyScroll(modalRef.current)
    } else {
      disableBodyScroll(modalRef.current)
    }
  }, [isOpen])

  if (!option) {
    return null;
  }

  return (
    <Modal
      ref={modalRef}
      isOpen={option.isOpen}
      onRequestClose={() => {
        modalStore.closeModal();
      }}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: "0px",
          border: "none",
          width: "95%"
        }
      }}
      overlayClassName="modal_overlay"
    >
      {
        option.title ?
          <div className='modal-title'><h1>{option.title}</h1></div> : null
      }
      <div className="confirm" ref={ref} style={{padding: "20px"}}>
        <div style={{ textAlign: 'center' }}>
          <ul>
            {option.message.split('\n').map((value, key) => {
              return (
                <li key={key}>
                  <span>{value}</span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className='modal-symbol'>
          {option.showSymbol && <img src="/images/login_symbol.png" alt='Symbol' />}
        </div>
        { option.messageJsx ?? null }
        <div className="btn_area">
          <button
            className="cancel"
            onClick={() => {
              modalStore.closeModal();
            }}
          >
            { option.cancelButtonText ?? "취소" }
          </button>
          <button
            className="ok"
            onClick={() => {
              modalStore.okModal();
            }}
          >
            { option.okButtonText ?? '확인' }
          </button>
        </div>
      </div>
    </Modal>
  );
}));

export { ConfirmModal };
