import {computed} from 'mobx';
import autobind from 'autobind-decorator';
import {asyncAction} from 'mobx-utils';
import Cookie from '../utils/cookie';
import {CommonService} from '../services';

@autobind
class CommonStore {
  modalStore;

  constructor(stores) {
    this.modalStore = stores.modalStore;
  }

  @computed
  get loginCheck() {
    const check = Cookie.getCookie('session_id');

    return !!check;
  }

  @asyncAction
  *isLogin() {
    if (!Cookie.getCookie('session_id')) {
      return {
        code: 400,
        message: '로그인 하시기 바랍니다.'
      };
    }

    return {
      code: 200
    };
  }

  @asyncAction
  *    getCertPhoneEncData(param = {}) {
    const { resultUrl } = param;

    if (!param) {
      return;
    }
    if (!resultUrl) {
      return;
    }

    const res = yield CommonService.getPhoneEncData(resultUrl);

    return res;
  }

  @asyncAction
  *getMemberByEmail(email) {

    if (!email) {
      return;
    }

    return yield CommonService.getMemberByEmail(email);
  }

  @asyncAction
  *getCertPhonePage(param = {}) {
    const { encData } = param;

    if (!param) {
      return;
    }
    if (!encData) {
      return;
    }

    var form = document.createElement('form');
    form.setAttribute('charset', 'UTF-8');
    form.setAttribute('method', 'POST');
    form.setAttribute(
      'action',
      'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb'
    );

    var hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'm');
    hiddenField.setAttribute('value', 'checkplusSerivce');
    form.appendChild(hiddenField);

    hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'EncodeData');
    hiddenField.setAttribute('value', encData);
    form.appendChild(hiddenField);

    document.body.appendChild(form);
    form.submit();
  }

  @asyncAction
  *getCertIpinPage(param = {}) {
    const { encData } = param;

    if (!param) {
      return;
    }
    if (!encData) {
      return;
    }

    var form = document.createElement('form');
    form.setAttribute('charset', 'UTF-8');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', 'https://cert.vno.co.kr/ipin.cb');

    var hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'm');
    hiddenField.setAttribute('value', 'pubmain');
    form.appendChild(hiddenField);

    hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', 'enc_data');
    hiddenField.setAttribute('value', encData);
    form.appendChild(hiddenField);

    document.body.appendChild(form);
    form.submit();
  }

  @asyncAction
  *getCertIpinEncData(param = {}) {
    const { resultUrl } = param;

    if (!param) {
      return;
    }
    if (!resultUrl) {
      return;
    }

    const res = yield CommonService.getIpinEncData(resultUrl);

    return res;
  }

  @asyncAction
  *friendAdd(param = {}) {
    const { mb_id, friend_id, name } = param;

    if (!param) {
      return;
    }
    if (!mb_id || !friend_id) {
      return;
    }

    const res = yield CommonService.friendAdd(mb_id, friend_id);

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: `${name}님을 친구로 등록하였습니다.`
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message
      });
    }

    return res;
  }

  @asyncAction
  *actionBlackAdd(param = {}, callback) {
    const { mbNick } = param;

    if (!param) {
      return;
    }
    if (!mbNick) {
      return;
    }

    const res = yield CommonService.blackAdd(mbNick);

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: `${mbNick}님이 블랙리스트에 등록되었습니다.`,
        callback: () => {
          callback && callback(res);
        }
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message
      });
    }

    return res.code === 200;
  }

  @asyncAction
  *actionBlackDelete(param = {}) {
    const { deleteMbId } = param;

    if (!param) {
      return;
    }
    if (!deleteMbId) {
      return;
    }

    const res = yield CommonService.blackDelete(deleteMbId);

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: `블랙리스트에서 해제하였습니다.`
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message
      });
    }

    return res.code === 200;
  }

  @asyncAction
  *actionCertifyEmail(param) {
    const { email, resultUrl } = param;

    if (!param) {
      return;
    }
    if (!email) {
      this.modalStore.openAlertModal({ message: `이메일을 입력해 주세요.` });
      return;
    }
    if (!resultUrl) {
      this.modalStore.openAlertModal({ message: `완료 URL을 입력해 주세요.` });
      return;
    }

    const res = yield CommonService.certifyEmail(email, resultUrl);

    if (res.code !== 200) {
      this.modalStore.openAlertModal({
        message: res.message
      });
    }

    return res;
  }

  @asyncAction
  *actionCertifyEmailCode(param) {
    const { email, code } = param;

    if (!param) {
      return;
    }
    if (!email) {
      this.modalStore.openAlertModal({
        message: `이메일이 존재 하지 않습니다.`
      });
      return;
    }
    if (!code) {
      this.modalStore.openAlertModal({ message: `인증번호를 입력해 주세요.` });
      return;
    }

    const res = yield CommonService.certifyEmailCode(email, code);

    if (res.code !== 200) {
      this.modalStore.openAlertModal({
        message: res.message
      });
    }

    return res;
  }

  @asyncAction
  *actionMyCertifyEmailToken(param) {
    const { token } = param;

    if (!param) {
      return;
    }
    if (!token) {
      return;
    }

    const res = yield CommonService.myCertifyEmailToken(token);

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: `인증 완료`
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message
      });
    }

    return res;
  }

  @asyncAction
  *actionMemberLeave(param, successCallback, failCallback) {
    const { token } = param;

    if (!param) {
      return;
    }
    if (!token) {
      return;
    }

    const res = yield CommonService.actionMemberLeave(token);

    if (res.code === 200) {
      this.modalStore.openAlertModal({
        message: `탈퇴가 완료 되었습니다.`,
        callback: () => {
          successCallback && successCallback(res);
        }
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message,
        callback: () => {
          failCallback && failCallback(res);
        }
      });
    }

    return res;
  }
}

export default CommonStore;
