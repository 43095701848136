import Api from '../api';
import {Url} from '../../constants/environment';

class AuthService extends Api {
  constructor(props) {
    super(props);
  }

  /**
   * @description 로그인
   * @returns
   */
  async register(email, phoneNumber, password) {
    if (!email || !phoneNumber || !password) {
      return;
    }

    const form = new FormData();
    form.append('email', email);
    form.append('phoneNumber', phoneNumber);
    form.append('password', password);

    const res = await this.commonAjax(
      'post',
      Url.named,
      `users`,
      form,
      {},
      false
    );

    return res;
  }

  /**
   * @description 로그인
   * @returns
   */
  async login(email, password, recaptcha) {
    if (!email || !password) {
      return;
    }

    const form = new FormData();
    form.append('email', email);
    form.append('password', password);
    form.append('recaptcha', recaptcha);

    const res = await this.commonAjax(
      'post',
      Url.named,
      `login`,
      form,
      {},
      false
    );

    return res;
  }

  /**
   * @description 지정IP 인증 로그인
   * @param email
   * @param password
   * @param certifyToken
   * @returns {Promise<*>}
   */
  async loginIp(email, password, certifyToken) {
    if (!email || !password || !certifyToken) {
      return;
    }

    const form = new FormData();
    form.append('email', email);
    form.append('password', password);

    const url = `certify-tokens/${certifyToken}/login-ip`;

    const res = await this.commonAjax('post', Url.named, url, form, {}, false);

    return res;
  }

  /**
   * @description 아이디 찾기
   * @returns
   */
  async idFind(certifyToken) {
    if (!certifyToken) {
      return;
    }

    const res = await this.commonAjax(
      'get',
      Url.named,
      `certify-tokens/${certifyToken}/email`
    );

    return res;
  }

  /**
   * @description 비밀번호 재설정
   * @returns
   */
  async passwordChange(certify_token, email, password) {
    if (!certify_token || !email || !password) {
      return;
    }

    const form = new URLSearchParams();
    form.append('email', email);
    form.append('password', password);

    const res = await this.commonAjax(
      'put',
      Url.named,
      `certify-tokens/${certify_token}/password`,
      form
    );

    return res;
  }

  /**
   * @description 비밀번호 재설정
   * @returns
   */
  async passwordChangeByPhone(certify_token, password) {
    if (!certify_token || !password) {
      return;
    }

    const form = new URLSearchParams();
    form.append('certify_token', certify_token);
    form.append('password', password);

    return this.commonAjax(
      'post',
      Url.named,
      `users/resetPassword`,
      form
    );
  }

  /**
   * @description 비밀번호 변경
   * @returns
   */
  async secondPasswordChange(certifyToken, password) {
    if (!certifyToken || !password) {
      return;
    }

    const form = new URLSearchParams();
    form.append('certify_token', certifyToken);
    form.append('second_password', password);

    const res = await this.commonAjax(
      'put',
      Url.named,
      `me/second-password`,
      form
    );

    return res;
  }

  /**
   * @description 휴면계정 해제
   * @returns
   */
  async dormancyRelease(certify_token) {
    if (!certify_token) {
      return;
    }

    const res = await this.commonAjax(
      'put',
      Url.named,
      `certify-tokens/${certify_token}/active`
    );

    return res;
  }

  /**
   * @description 로그아웃
   * @returns
   */
  async logout() {
    const res = await this.commonAjax('delete', Url.named, `logout`);

    return res;
  }

  /**
   * @description Call to logout iframe chat
   * @returns
   */
  async logoutIframe() {
    return new Promise((resolve, reject) => {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.width = "100";
      iframe.height = "100";
      iframe.src = Url.namedWeb+"/portal_api/logout.php";

      iframe.onload = () => {
        console.log('Logout successfully.');
        resolve(true);
      };

      iframe.onerror = () => {
        console.log('Logout failed.');
        resolve(false);
      };

      document.body.appendChild(iframe);
    });
  }

}

export default new AuthService();