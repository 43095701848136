import autobind from 'autobind-decorator';
import { computed, observable, runInAction, toJS } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { Url } from '../constants/environment';
import { AuthService, UsersService, MemoService } from '../services';
import Cookie from '../utils/cookie';
var MD5 = require('md5.js');

@autobind
class UsersStore {
  modalStore;

  constructor(stores) {
    this.modalStore = stores.modalStore;
  }

  @observable
  code = 200;

  @observable
  message = '';

  @observable
  data = {
    background_image_path: '/images/thumb_bg_def.png',
    certify: {
      is_phone_certify: null,
      is_ipin_certify: null,
      name: ""
    },
    email: '',
    gender: '',
    info_point: 0,
    is_email_certify: false,
    is_second_password: true,
    second_password_date: true,
    item: {
      change_nick: 0,
      change_today_word: 0,
      change_profile_image: 0,
      change_background_image: 0
    },
    level: 0,
    login_ip: { total_count: 0, list: [] },
    mb_id: '',
    nick: '',
    password_change_date: '',
    profile_image_path: null,
    today_word: null,
    cash: 0,
    point: 0,
    gp: 0,
    tp: 0,
    payment_medal: 0,
    profit_medal: 0,
    total_medal: 0,
    notify_unread_cnt: 0,
    is_admin: false,
    today_exchange_medal: 0,
    need_phone_verify: false,
    need_phone_verify_after_reset_pw: false
  };

  @observable
  notifyList = [];

  @observable
  inboxList = [];

  /**********************************************************
   * computed
   **********************************************************/
  @computed
  get me() {
    let result = {
      certify: this.data.certify,
      level: this.data.level,
      nick: this.data.nick,
      today_word: !!this.data.today_word ? this.data.today_word : '',
      profile_img: '/images/thumb_user_def.png',
      mb_id: this.data.mb_id,
      background_image_path: '/images/thumb_bg_def.png',
      cash: this.data.cash,
      point: this.data.point,
      gp: this.data.gp,
      notice_list: this.data.notice_list,
      tp: this.data.tp,
      payment_medal: this.data.payment_medal,
      profit_medal: this.data.profit_medal,
      total_medal: this.data.total_medal,
      today_exchange_medal: this.data.today_exchange_medal,
      need_phone_verify: this.data.need_phone_verify,
      need_phone_verify_after_reset_pw: this.data.need_phone_verify_after_reset_pw,
    };

    if (!!this.data.profile_image_path) {
      result.profile_img = `${Url.thumb}normal/crop/112x112${this.data.profile_image_path}`;
    }

    if (!!this.data.background_image_path) {
      result.background_image_path = `${Url.thumb}normal/crop/112x112${this.data.background_image_path}`;
    }

    return result;
  }

  @computed
  get notifyArr() {
    const { notifyList } = this;

    if (notifyList.length > 0) {
      return notifyList;
    } else {
      return [];
    }
  }

  @computed
  get isNotifyDotActive() {
    const { data } = this;
    const { notify_unread_cnt } = data;

    return +notify_unread_cnt > 0;
  }

  @computed
  get inboxArr() {
    const { inboxList } = this;

    if (inboxList.length > 0) {
      return inboxList;
    } else {
      return [];
    }
  }

  @computed
  get isInboxDotActive() {
    return this.inboxList?.filter(item => !item.is_read).length > 0;
  }

  /**********************************************************
   * async actions
   **********************************************************/
  @asyncAction
  *logout(callback) {
    this.doLogout();
  }

  async doLogout(callback) {
    try {
      const success = await AuthService.logoutIframe();
      if (success) {
        await this.logoutFromAPI(callback);
      }
    } catch (error) {
      console.log(error);
    }
  }

  @asyncAction
  *logoutFromAPI(callback) {
    const res = yield AuthService.logout();
    if (res.code === 200) {
      Cookie.deleteCookie('session_id');
      Cookie.deleteCookie(new MD5().update('ck_mb_id').digest('hex'));
      Cookie.deleteCookie(new MD5().update('ck_auto').digest('hex'));
      Cookie.deleteCookie(new MD5().update('token').digest('hex'));
      callback ? callback() : window.location.reload();
    }
  }

  @asyncAction
  *getInfo() {
    if (!Cookie.getCookie('session_id')) {
      return;
    }

    const res = yield UsersService.getInfo();

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
        this.resultDataUpdate(res.data);
      });
    } else {
      this.modalStore.openAlertModal({
        message: res.message
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *actionUserFind(param, callback) {
    const { nick } = param;

    if (!param) {
      return;
    }
    if (!nick) {
      this.modalStore.openAlertModal({
        message: '조회 하실 닉네임을 입력하세요'
      });
      return;
    }

    const res = yield UsersService.userFind(nick);

    if (res.code === 200) {
      runInAction(() => {
        this.code = res.code;
      });

      callback && callback(res);
    } else {
      this.modalStore.openAlertModal({
        message: res.message
      });

      runInAction(() => {
        this.code = res.code;
        this.message = res.message;
      });
    }
  }

  @asyncAction
  *getNofifyList() {
    try {
      const result = yield UsersService.getNotify();

      if (result.code === 200) {
        this.notifyList = result.data.list;
        this.data = {
          ...this.data,
          notify_unread_cnt: result.data.unread_cnt
        };
      }
    } catch (error) {
      console.log(error);
    }
  }

  @asyncAction
  *putNotifyItem(notifyId) {
    try {
      const result = yield UsersService.putNotify(notifyId);

      console.log(result);

      if (result.code === 200) {
        this.notifyList = result.data.list;
        this.data = {
          ...this.data,
          notify_unread_cnt: result.data.unread_cnt
        };

        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  @asyncAction
  *deleteNotifyItem(notifyId) {
    try {
      const result = yield UsersService.deleteNotifyById(notifyId);

      console.log(result);

      if (result.code === 200) {
        this.notifyList = result.data.list;
        this.data = {
          ...this.data,
          notify_unread_cnt: result.data.unread_cnt
        };

        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  @asyncAction
  *deleteNotifyItemAll() {
    try {
      const result = yield UsersService.deleteNotifyAll();

      console.log(result);

      if (result.code === 200) {
        this.notifyList = result.data.list;
        this.data = {
          ...this.data,
          notify_unread_cnt: result.data.unread_cnt
        };

        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  @asyncAction
  *getInboxList() {
    try {
      const result = yield MemoService.getMemo();

      if (result.code === 200) {
        this.inboxList = result.data.list;
      }
    } catch (error) {
      console.log(error);
    }
  }

  @asyncAction
  *deleteInboxItems(memoIds) {
    try {
      const result = yield MemoService.deleteMemoByIds(memoIds);

      console.log(result);

      if (result.code === 200) {
        this.inboxList = this.inboxList.filter(item => !memoIds.includes(item.me_id));

        return true;
      } else {
        if (result.message && typeof result.message === 'string') {
          this.modalStore.openAlertModal({
            message: result.message
          });
        }

        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  @asyncAction
  *deleteAllReadMemo() {
    try {
      const allReadMemoIds = this.inboxList.filter(item => item.is_read).map(item => item.me_id);

      // If there is no read memo, return false and don't do anything.
      if (!allReadMemoIds || allReadMemoIds.length === 0) {
        return false;
      }

      const result = yield MemoService.deleteMemoByIds(allReadMemoIds);

      console.log(result);

      if (result.code === 200) {
        this.inboxList = this.inboxList.filter(item => !allReadMemoIds.includes(item.me_id));
        this.data = {
          ...this.data,
        };

        return true;
      } else {
        if (result.message && typeof result.message === 'string') {
          this.modalStore.openAlertModal({
            message: result.message
          });
        }

        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  resultDataUpdate(data) {
    runInAction(() => {
      this.data.background_image_path = !!data.background_image_path
        ? data.background_image_path
        : null;
      this.data.certify = !!data.certify ? data.certify : this.data.certify;
      this.data.email = !!data.email ? data.email : '';
      this.data.info_point = !!data.info_point ? data.info_point : 0;
      this.data.is_email_certify = !!data.is_email_certify
        ? data.is_email_certify
        : false;
      this.data.login_ip = !!data.login_ip ? data.login_ip : {};
      //      this.data.is_second_password = !!data.is_second_password
      //        ? data.is_second_password
      //        : false;
      this.data.is_second_password = true;
      //      this.data.second_password_date = !!data.second_password_date
      //        ? data.second_password_date
      //        : null;
      this.data.second_password_date = true;
      this.data.item.change_background_image = !!data.item
        .change_background_image
        ? data.item.change_background_image
        : 0;
      this.data.item.change_nick = !!data.item.change_nick
        ? data.item.change_nick
        : 0;
      this.data.item.change_profile_image = !!data.item.change_profile_image
        ? data.item.change_profile_image
        : 0;
      this.data.item.change_today_word = !!data.item.change_today_word
        ? data.item.change_today_word
        : 0;
      this.data.level = !!data.level ? data.level : 0;
      this.data.nick = !!data.nick ? data.nick : '';
      this.data.password_change_date = !!data.password_change_date
        ? data.password_change_date
        : '';
      this.data.profile_image_path = !!data.profile_image_path
        ? data.profile_image_path
        : null;
      this.data.today_word = !!data.today_word ? data.today_word : null;
      this.data.mb_id = !!data.mb_id ? data.mb_id : null;
      this.data.cash = !!data.cash ? data.cash : 0;
      this.data.point = !!data.point ? data.point : 0;
      this.data.gp = !!data.gp ? data.gp : 0;
      this.data.tp = !!data.tp ? data.tp : 0;
      this.data.payment_medal = !!data.payment_medal ? data.payment_medal : 0;
      this.data.profit_medal = !!data.profit_medal ? data.profit_medal : 0;
      this.data.total_medal = !!data.total_medal ? data.total_medal : 0;
      this.data.notice_list = !!data.notice_list ? data.notice_list : null;
      this.data.notify_unread_cnt = !!data.notify_unread_cnt
        ? data.notify_unread_cnt
        : 0;
      this.data.is_admin = data.is_admin;
      this.data.today_exchange_medal = data.today_exchange_medal;
      this.data.need_phone_verify = data.need_phone_verify;
      this.data.need_phone_verify_after_reset_pw = data.need_phone_verify_after_reset_pw;
    });
  }
}

export default UsersStore;
