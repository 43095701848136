import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import SportsTeamLogo from '../../../../components/teamLogo';
import { FormatUtil } from '../../../../common/utils/formatUtil';
import { getStreamBrowser } from '../../liveStream/util';

const BaseballView = observer(({ item, setSelectedGame, isSelected, fromHome }) => {
  const {
    getTeamData,
    statusText,
    getTracker,
    winnerTeam,
    id,
    startDatetime,
    getStartDateTime,
    gameStatus,
    cheerCount,
  } = item;

  const setSelectedItem = () => {
    setSelectedGame(isSelected, id);
  };
  const onClickVideo = () => {
    getStreamBrowser(item.videoLink);
  };

  return (
    <div className={clsx('item', 'baseball', getTracker.status)}>
      <Link
        className='box'
        to={`/match/baseball/${id}/cheer?fromHome=${fromHome}`}
        onClick={() => window.reflowOnFocus()}
      >
        <div className='team left'>
          <span className='name'>{getTeamData.home.name}</span>
          <SportsTeamLogo
            className='logo'
            imgSize={30}
            imgUrl={getTeamData.home.imgPath}
            isLazy={false}
            location='home'
          />
        </div>
        <div className='info'>
          <div className='score'>
            <strong className={clsx('left', winnerTeam.activeScore === 'away' && 'on')}>
              <em className='num'>{getTeamData.away.score}</em>
            </strong>
            <strong className={clsx('right', winnerTeam.activeScore === 'home' && 'on')}>
              <em className='num'>{getTeamData.home.score}</em>
            </strong>
          </div>

          <div className='state'>
            {getTracker.status === 'progress' && <span>{statusText}</span>}
            {getTracker.status === 'final' && statusText}
            <span className='clock'>{getStartDateTime}</span>
            {cheerCount >= 100 && <span className='count'>{FormatUtil.numberUnitConvert(cheerCount)}</span>}
          </div>

          <div className='time'>
            <div>
              <span className='clock'>{getStartDateTime}</span>
              {cheerCount >= 100 && <span className='count'>{FormatUtil.numberUnitConvert(cheerCount)}</span>}
            </div>
          </div>
        </div>
        <div className='team right'>
          <SportsTeamLogo
            className='logo'
            imgSize={30}
            imgUrl={getTeamData.away.imgPath}
            isLazy={false}
            location='away'
          />
          <div className='name'>{getTeamData.away.name}</div>
        </div>
      </Link>
      {!!item.videoLink && (gameStatus === 'IN_PROGRESS' || gameStatus === 'BREAK_TIME' || gameStatus === 'PAUSE') && (
        <button type='button' className='on_air' title='생중계' onClick={onClickVideo}>
          생중계
        </button>
      )}
      <button
        type='button'
        className={
          !!item.videoLink && (gameStatus === 'IN_PROGRESS' || gameStatus === 'BREAK_TIME' || gameStatus === 'PAUSE')
            ? clsx('btn_favorites', isSelected && 'selected')
            : clsx('live', clsx('btn_favorites', isSelected && 'selected'))
        }
        onClick={setSelectedItem}
      >
        관심경기 추가
      </button>
    </div>
  );
});

export default BaseballView;
